import * as React from 'react';
import Layout from '../../components/Layout';
import ArticleLink from '../../components/ArticleLink';
import { Box, Container, Stack, Typography } from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Guides(): JSX.Element {
  return (
    <Layout title="Jewellery Guides | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="guides" />
        <Box flex={1} mb={2}>
          <Container>
            <Typography variant="h1" gutterBottom>
              Guides
            </Typography>
            <ArticleLink
              href="/guides/birthstones"
              title="Birthstones"
              text="A birthstone is a gemstone most often in the form of a ring or pendant. Each
                stone is traditionally associated with various qualities that
                symbolize the month of birth in the Gregorian calendar."
            />
            <ArticleLink
              href="/guides/traditional-anniversary-gifts"
              title="Traditional Anniversary Gifts"
              text="The symbolic gifts associated with anniversaries have changed
                over time and vary from country to country. This article lists the
                gemstones and metals most commonly associated with anniversaries in
                the United Kingdom."
            />
            <ArticleLink
              href="/guides/ring-sizing"
              title="Ring Sizing"
              text="Not sure what size ring you need? In this article article you
                will learn how to convert between various size systems and
                measurements. Our staff are also able to measure your ring size in
                store."
            />
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}
