import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';

type ArticleLinkProps = {
  href: string;
  title: string;
  text: string;
};

export default function ArticleLink({href, title, text}: ArticleLinkProps): JSX.Element {
  return (
    <Box mb={4}>
      <Link href={href}>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="body1">
          {text}
          <Typography variant="body2" component="span" ml={1} color="primary">Read more...</Typography>
        </Typography>
      </Link>
    </Box>
  );
}
